package com.tanujnotes.components.widgets

import androidx.compose.runtime.Composable
import com.tanujnotes.SubheadlineTextStyle
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.style.toAttrs
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Div

@Composable
fun PostCard(postTitle: String, postSubTitle: String, postDate: String, postMinRead: String, onClickPath: String) {
    val ctx = rememberPageContext()
    Box(
        modifier = Modifier
            .fillMaxWidth()
            .boxShadow(
                color = Colors.Black.copy(alpha = 10),
                blurRadius = 50.px,
                spreadRadius = 50.px
            )
            .padding(all = 24.px)
            .borderRadius(10.px)
            .background(Colors.Gray.copy(alpha = 50))
            .onClick { ctx.router.tryRoutingTo(onClickPath) }
    ) {
        Column {
            Div {
                SpanText(
                    postTitle, Modifier.color(
                        when (ColorMode.current) {
                            ColorMode.LIGHT -> Colors.Black
                            ColorMode.DARK -> Colors.White
                        }
                    )
                        .fontSize(1.2.cssRem)
                        .fontWeight(FontWeight.Bold)
                )
            }
            Div {
                SpanText(
                    postSubTitle,
                    Modifier
                        .fontSize(.95.cssRem)
                        .color(Colors.Gray)
                )
            }
            Div(SubheadlineTextStyle.toAttrs()) {
                SpanText(
                    "$postDate · $postMinRead", Modifier
                        .fontSize(.9.cssRem)
                        .color(Colors.Gray)
                )
            }
        }
    }
}