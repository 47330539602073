package com.tanujnotes.pages

import androidx.compose.runtime.*
import com.varabyte.kobweb.core.*
import com.varabyte.kobwebx.markdown.*

@Page
@Composable
fun AboutPage() {
    com.tanujnotes.components.layouts.MarkdownLayout("About") {
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("To be updated...")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Meanwhile, you can check out my profile on X/Twitter @tanujnotes")
        }
        org.jetbrains.compose.web.dom.P {
            com.varabyte.kobweb.silk.components.navigation.Link("https://twitter.com/tanujnotes") {
                org.jetbrains.compose.web.dom.Text("https://twitter.com/tanujnotes")
            }
        }
    }
}
