package com.tanujnotes.pages

import androidx.compose.runtime.*
import com.varabyte.kobweb.core.*
import com.varabyte.kobwebx.markdown.*

@Page
@Composable
fun OlauncherPage() {
    com.tanujnotes.components.layouts.MarkdownLayout("Olauncher") {
        org.jetbrains.compose.web.dom.H2(attrs = { id("olauncher-minimal-af-launcher") }) {
            org.jetbrains.compose.web.dom.Text("Olauncher | Minimal AF Launcher")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Are you using your phone, or is your phone using you? Reduce your screen time and save hours every day by using Olauncher.")
        }
        org.jetbrains.compose.web.dom.H4(attrs = { id("why-olauncher") }) {
            org.jetbrains.compose.web.dom.Text("Why Olauncher?")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Olauncher is a minimal AF launcher, designed to reduce your screen time. It's free & open-source, doesn't collect any data and has daily new wallpapers. By the way, AF means Ad-Free! 😎")
        }
        org.jetbrains.compose.web.dom.H4(attrs = { id("install") }) {
            org.jetbrains.compose.web.dom.Text("Install")
        }
        org.jetbrains.compose.web.dom.P {
            com.varabyte.kobweb.silk.components.navigation.Link("https://play.google.com/store/apps/details?id=app.olauncher") {
                org.jetbrains.compose.web.dom.Text("Play Store")
            }
            org.jetbrains.compose.web.dom.Text("  ·  ")
            com.varabyte.kobweb.silk.components.navigation.Link("https://f-droid.org/en/packages/app.olauncher/") {
                org.jetbrains.compose.web.dom.Text("F-Droid")
            }
            org.jetbrains.compose.web.dom.Text("  ·  ")
            com.varabyte.kobweb.silk.components.navigation.Link("https://github.com/tanujnotes/Olauncher") {
                org.jetbrains.compose.web.dom.Text("Github")
            }
        }
        org.jetbrains.compose.web.dom.H3(attrs = { id("faqs") }) {
            org.jetbrains.compose.web.dom.Text("FAQs:")
        }
        com.varabyte.kobweb.compose.dom.GenericTag("details") {
            com.varabyte.kobweb.compose.dom.GenericTag("summary") {
                org.jetbrains.compose.web.dom.Text("Performance issues")
            }
            org.jetbrains.compose.web.dom.Text("If you’re on Android 14 or above, please turn off the battery optimization (allow background usage) for Olauncher from your phone settings. If you’re using Android 13 or below, just restart your phone once. You can try both, just to be on the safe side. :)")
        }

        com.varabyte.kobweb.compose.dom.GenericTag("details") {
            com.varabyte.kobweb.compose.dom.GenericTag("summary") {
                org.jetbrains.compose.web.dom.Text("Weather, widgets, and custom fonts.")
            }
            org.jetbrains.compose.web.dom.Text("Widgets and many other features are available in the pro version called Pro Launcher.")
        }

        com.varabyte.kobweb.compose.dom.GenericTag("details") {
            com.varabyte.kobweb.compose.dom.GenericTag("summary") {
                org.jetbrains.compose.web.dom.Text("Where are my hidden apps?")
            }
            org.jetbrains.compose.web.dom.Text("Long press anywhere on Olauncher home screen to open settings. Tap on the 'Olauncher' text on the top to see your hidden apps.")
        }

        com.varabyte.kobweb.compose.dom.GenericTag("details") {
            com.varabyte.kobweb.compose.dom.GenericTag("summary") {
                org.jetbrains.compose.web.dom.Text("Bottom navigation gesture not working")
            }
            org.jetbrains.compose.web.dom.Text("Some phones/companies (Xiaomi, etc) do not support navigation gestures with downloaded launchers. Unfortunately, we app developers can’t do anything about it. As a workaround, you can use apps like FNG to add gesture navigation to your device.")
        }

        com.varabyte.kobweb.compose.dom.GenericTag("details") {
            com.varabyte.kobweb.compose.dom.GenericTag("summary") {
                org.jetbrains.compose.web.dom.Text("How to set my own wallpapers?")
            }
            org.jetbrains.compose.web.dom.Text("You can set any wallpaper you want from your phone settings or Gallery/Photos app. Tip: you can long press On/Off next to Daily wallpaper text to have a black wallpaper.")
        }

        com.varabyte.kobweb.compose.dom.GenericTag("details") {
            com.varabyte.kobweb.compose.dom.GenericTag("summary") {
                org.jetbrains.compose.web.dom.Text("How to change the text color?")
            }
            org.jetbrains.compose.web.dom.Text("Go to Olauncher settings and change Theme. It changes the text color to either black or white.")
        }

        com.varabyte.kobweb.compose.dom.GenericTag("details") {
            com.varabyte.kobweb.compose.dom.GenericTag("summary") {
                org.jetbrains.compose.web.dom.Text("Center align all apps.")
            }
            org.jetbrains.compose.web.dom.Text("Go to settings and long press on Left/Center/Right - whatever you current alignment is - and it will apply to all apps.")
        }

        com.varabyte.kobweb.compose.dom.GenericTag("details") {
            com.varabyte.kobweb.compose.dom.GenericTag("summary") {
                org.jetbrains.compose.web.dom.Text("Double-tap to lock not working.")
            }
            org.jetbrains.compose.web.dom.Text("Enable Autostart and disable battery optimizations for Olauncher from your phone settings.")
        }

        com.varabyte.kobweb.compose.dom.GenericTag("details") {
            com.varabyte.kobweb.compose.dom.GenericTag("summary") {
                org.jetbrains.compose.web.dom.Text("How to rename apps?")
            }
            org.jetbrains.compose.web.dom.Text("To rename app drawer apps - long press on them to see the Rename option. To rename home screen apps - long press on any home screen app and then start typing. You'll see the RENAME option. You can also rename to emojis. 😃")
        }

        com.varabyte.kobweb.compose.dom.GenericTag("details") {
            com.varabyte.kobweb.compose.dom.GenericTag("summary") {
                org.jetbrains.compose.web.dom.Text("Disable left/right swipe gestures.")
            }
            org.jetbrains.compose.web.dom.Text("Long press on left/right swipe app names in Olauncher settings to enable or disable them.")
        }

        com.varabyte.kobweb.compose.dom.GenericTag("details") {
            com.varabyte.kobweb.compose.dom.GenericTag("summary") {
                org.jetbrains.compose.web.dom.Text("Landscape mode.")
            }
            org.jetbrains.compose.web.dom.Text("Play Store version only supports landscape on tablets . If you want it for your phone, you can download and install landscape APK from Github releases.")
        }

        com.varabyte.kobweb.compose.dom.GenericTag("details") {
            com.varabyte.kobweb.compose.dom.GenericTag("summary") {
                org.jetbrains.compose.web.dom.Text("Daily wallpaper is not looking right.")
            }
            org.jetbrains.compose.web.dom.Text("Turn it Off and On from settings. That usually fixes the issue. Please make sure you're connected to the Internet.")
        }

        com.varabyte.kobweb.compose.dom.GenericTag("details") {
            com.varabyte.kobweb.compose.dom.GenericTag("summary") {
                org.jetbrains.compose.web.dom.Text("Set app usage limits.")
            }
            org.jetbrains.compose.web.dom.Text("Android phones have a Digital Wellbeing app which can set app usage limits. Please search ‘Digital Wellbeing’ in your phone settings. Learn more.")
        }

        com.varabyte.kobweb.compose.dom.GenericTag("details") {
            com.varabyte.kobweb.compose.dom.GenericTag("summary") {
                org.jetbrains.compose.web.dom.Text("Not able to uninstall Olauncher.")
            }
            org.jetbrains.compose.web.dom.Text("It’s because of one of these two reasons: 1. Phones with Admin permission cannot be uninstalled. If you’ve enabled double tap to lock by giving it Admin permission, turn it off. 2. Some phones do not let you uninstall your default launcher. Tap on 'Change default launcher' in settings and select a different launcher from the list. Now you can uninstall. Thank you for giving Olauncher a try. Wishing you the best!")
        }

        org.jetbrains.compose.web.dom.H3(attrs = { id("tips") }) {
            org.jetbrains.compose.web.dom.Text("Tips:")
        }
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("Swipe down on the app drawer to easily dismiss it.")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("Start scrolling the app drawer to hide the keyboard.")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("Press the search icon 🔍 on your keyboard to launch the first app in the list. If there are no search results, it'll search the Play Store.")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("To temporarily disable the auto launch, press SPACE on your keyboard and then type to search.")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("Tap on time and date to open alarm and calendar respectively. Long press on time or date to select which apps to open.")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("Long press on ‘Digital Wellbeing’ text in settings to hide it. Note: It only shows up if you’re not using the Digital Wellbeing app. Learn more.")
            }
        }
        org.jetbrains.compose.web.dom.H3(attrs = { id("how-can-i-support-olauncher") }) {
            org.jetbrains.compose.web.dom.Text("How can I support Olauncher?")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Olauncher is free and always will be. The best thing you can do is copy the text below and share it as much as possible. Olauncher needs people, and people need Olauncher.")
        }
        com.varabyte.kobweb.compose.dom.GenericTag("blockquote") {
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Reduce your screen time. Try Olauncher, free forever! ")
                com.varabyte.kobweb.silk.components.navigation.Link("https://play.google.com/store/apps/details?id=app.olauncher") {
                    org.jetbrains.compose.web.dom.Text("https://play.google.com/store/apps/details?id=app.olauncher")
                }
            }
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("You can also ")
            com.varabyte.kobweb.silk.components.navigation.Link("https://buymeacoffee.com/tanujnotes") {
                org.jetbrains.compose.web.dom.Text("buy me a coffee")
            }
            org.jetbrains.compose.web.dom.Text(" or purchase ")
            com.varabyte.kobweb.silk.components.navigation.Link("https://play.google.com/store/apps/dev?id=7198807840081074933") {
                org.jetbrains.compose.web.dom.Text("my other apps")
            }
            org.jetbrains.compose.web.dom.Text(". That’s always appreciated. Thank you! ❤️")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Follow me on X/Twitter ")
            com.varabyte.kobweb.silk.components.navigation.Link("https://twitter.com/tanujnotes/") {
                org.jetbrains.compose.web.dom.Text("@tanujnotes")
            }
            org.jetbrains.compose.web.dom.Text(" for regular updates.")
        }
    }
}
