package com.tanujnotes.pages

import androidx.compose.runtime.Composable
import com.tanujnotes.HeadlineTextStyle
import com.tanujnotes.SubheadlineTextStyle
import com.tanujnotes.components.layouts.PageLayout
import com.tanujnotes.components.widgets.PostCard
import com.tanujnotes.toSitePalette
import com.varabyte.kobweb.compose.css.StyleVariable
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.components.navigation.UncoloredLinkVariant
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.base
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.style.toAttrs
import com.varabyte.kobweb.silk.components.style.vars.color.ColorVar
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.vh
import org.jetbrains.compose.web.dom.Div

// Container that has a tagline and grid on desktop, and just the tagline on mobile
val HeroContainerStyle by ComponentStyle {
    base { Modifier.fillMaxWidth().gap(2.cssRem) }
    Breakpoint.MD { Modifier.margin { top(20.vh) } }
}

// A demo grid that appears on the homepage because it looks good
val HomeGridStyle by ComponentStyle.base {
    Modifier
        .gap(0.5.cssRem)
        .width(70.cssRem)
        .height(18.cssRem)
}

private val GridCellColorVar by StyleVariable<Color>()
val HomeGridCellStyle by ComponentStyle.base {
    Modifier
        .backgroundColor(GridCellColorVar.value())
        .boxShadow(blurRadius = 0.6.cssRem, color = GridCellColorVar.value())
        .borderRadius(1.cssRem)
}

@Page
@Composable
fun HomePage() {
    PageLayout("@tanujnotes") {
        Box {
            // val sitePalette = ColorMode.current.toSitePalette()
            Column(Modifier.gap(2.cssRem)) {
                Div(HeadlineTextStyle.toAttrs()) {
                    SpanText(
                        "Hello, world!", Modifier.color(
                            when (ColorMode.current) {
                                ColorMode.LIGHT -> Colors.Black
                                ColorMode.DARK -> Colors.White
                            }
                        )
                    )
                }

                Div(SubheadlineTextStyle.toAttrs()) {
                    val sitePalette = ColorMode.current.toSitePalette()
                    SpanText("My name is Tanuj. I'm building apps that I wish already existed. More on the ")
                    Link(
                        "https://play.google.com/store/apps/dev?id=7198807840081074933",
                        "Play Store.",
                        Modifier.setVariable(ColorVar, sitePalette.brand.primary),
                        variant = UncoloredLinkVariant
                    )
                }
                Spacer()
                PostCard(
                    "Olauncher - About & FAQs",
                    "Olauncher is a minimal launcher for Android, designed to help you reduce your screen time.",
                    "Jan 7, 2024",
                    "4 min",
                    "/olauncher"
                )

                Spacer()
//                    val ctx = rememberPageContext()
//                    Button(onClick = {
//                        // Change this click handler with your call-to-action behavior
//                        // here. Link to an order page? Open a calendar UI? Play a movie?
//                        // Up to you!
//                        ctx.router.tryRoutingTo("/about")
//                    }, colorScheme = ColorSchemes.Blue) {
//                        Text("This could be your CTA")
//                    }
            }
        }

//            Div(HomeGridStyle
//                .toModifier()
//                .displayIfAtLeast(Breakpoint.MD)
//                .grid {
//                    rows { repeat(3) { size(1.fr) } }
//                    columns { repeat(5) {size(1.fr) } }
//                }
//                .toAttrs()
//            ) {
//                val sitePalette = ColorMode.current.toSitePalette()
//                GridCell(sitePalette.brand.primary, 1, 1, 2, 2)
//                GridCell(ColorSchemes.Monochrome._600, 1, 3)
//                GridCell(ColorSchemes.Monochrome._100, 1, 4, width = 2)
//                GridCell(sitePalette.brand.accent, 2, 3, width = 2)
//                GridCell(ColorSchemes.Monochrome._300, 2, 5)
//                GridCell(ColorSchemes.Monochrome._800, 3, 1, width = 5)
//            }
    }
}
